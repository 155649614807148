import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import OtpInput from "./OtpInput"; // Import the OtpInput component
import axios from "axios"; // Import Axios
import "./PhoneOtpModal.css"; // Ensure this file exists
import { weburl } from "../helpers/ApiUrls";

const PhoneOtpModal = ({ isOpen, onClose, onVerify }) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isResendAllowed, setIsResendAllowed] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);

    useEffect(() => {
        if (!isOpen) {
            // Reset states when the modal is closed
            setPhoneNumber("");
            setOtp("");
            setIsOtpSent(false);
            setErrorMessage("");
            setIsResendAllowed(false); // Reset resend option
            setResendTimer(0);
        }
    }, [isOpen]);

    useEffect(() => {
        let timerId;

        if (resendTimer > 0) {
            timerId = setInterval(() => {
                setResendTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(timerId);
                        setIsResendAllowed(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timerId);
    }, [resendTimer]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [posState, setPosState] = useState(false);

    useEffect(() => {
        // Check for the isadmin meta tag
        const isAdminMeta = document.querySelector("meta[isadmin]");
        if (isAdminMeta && isAdminMeta.getAttribute("isadmin") === "true") {
            setIsAdmin(true);
        }

        // Get the posState from local storage
        const storedPosState = localStorage.getItem("posState");
        if (storedPosState === "true") {
            setPosState(true);
        }
    }, []);

    const handleSendOtp = async () => {
        // if (phoneNumber.length !== 10 || !/^\d{10}$/.test(phoneNumber)) {
        //     setErrorMessage("Please enter a valid 10-digit phone number.");
        //     return;
        // }

        // Validate email
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(phoneNumber)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        try {
            const response = await axios.post(
                `${weburl}/api/send-email-otp?pos=${
                    posState ? "true" : "false"
                }`,
                {
                    email: phoneNumber,
                }
            );
            setIsOtpSent(true);
            setErrorMessage("");
            setIsResendAllowed(false);
            setResendTimer(60); // Set 60 seconds timer for resend
            if (isAdmin && posState) {
                // Store the token in localStorage
                localStorage.setItem("authToken", response.data.token);
                localStorage.setItem("userId", response.data.user_id); // Store the user ID
                localStorage.setItem("phoneNumber", phoneNumber); // Save phone number in localStorage
                onVerify(phoneNumber);
                //onVerifyPhone(); // Trigger the callback to show UserAccountMenu in the parent
                onClose();
            }
        } catch (error) {
            setErrorMessage("Failed to send OTP. Please try again.");
        }
    };

    const handleVerifyOtp = async () => {
        try {
            const response = await axios.post(
                `${weburl}/api/email-verify-otp`,
                {
                    phone_number: phoneNumber,
                    otp: otp,
                }
            );

            if (response.data.message === "OTP verified successfully.") {
                // Store the token in localStorage
                localStorage.setItem("authToken", response.data.token);
                localStorage.setItem("userId", response.data.user_id); // Store the user ID
                localStorage.setItem("phoneNumber", phoneNumber); // Save phone number in localStorage
                onVerify(phoneNumber);
                //onVerifyPhone(); // Trigger the callback to show UserAccountMenu in the parent
                onClose();

                //alert('OTP verified successfully!');
                //window.location.reload(); // Reload page to reflect authentication changes
            } else {
                setErrorMessage("Invalid OTP. Please try again.");
            }
        } catch (error) {
            setErrorMessage("Failed to verify OTP. Please try again.");
        }
    };

    const handleResendOtp = async () => {
        if (phoneNumber.length !== 10 || !/^\d{10}$/.test(phoneNumber)) {
            setErrorMessage("Please enter a valid 10-digit phone number.");
            return;
        }

        try {
            await axios.post(`${weburl}/api/resend-otp`, {
                phone_number: phoneNumber,
            });
            setErrorMessage("");
            setIsResendAllowed(false);
            setResendTimer(60); // Reset timer for resend
        } catch (error) {
            setErrorMessage("Failed to resend OTP. Please try again.");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="phone-otp-modal-overlay" onClick={onClose}>
            <div
                className="phone-otp-modal-content"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    className="bg-dark  rounded-circle p-0 d-flex align-items-center ms-auto p-1"
                    style={{ width: 25, height: 25 }}
                    onClick={onClose}
                >
                    <i class="ri-close-line"></i>
                </button>
                {!isOtpSent ? (
                    <div>
                        <h4>Enter your email id</h4>
                        <div class="input-group mb-3">
                            <input
                                className="form-control"
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Email ID"
                                aria-label="Email ID"
                            />
                            <div class="input-group-append">
                                <button
                                    onClick={handleSendOtp}
                                    className="w-auto"
                                >
                                    Send OTP
                                </button>{" "}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h4>Enter the OTP</h4>
                        <OtpInput value={otp} onChange={setOtp} />
                        <button
                            onClick={handleVerifyOtp}
                            className=" px-3 rounded mt-2"
                        >
                            Verify OTP
                        </button>
                        {!isResendAllowed && resendTimer > 0 && (
                            <p>
                                Please wait {resendTimer} seconds before
                                resending OTP.
                            </p>
                        )}
                        {isResendAllowed && (
                            <button
                                onClick={handleResendOtp}
                                className=" bg-dark rounded mt-2 ms-2"
                            >
                                Resend OTP
                            </button>
                        )}
                    </div>
                )}
                {errorMessage && (
                    <p className="error-message">{errorMessage}</p>
                )}
            </div>
        </div>
    );
};

PhoneOtpModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onVerify: PropTypes.func.isRequired,
};

export default PhoneOtpModal;
